import styled from 'styled-components'

export const WrapperLanguage = styled.div`
  .ant-radio-button-wrapper {
    color: #fff;
    border: none;
    padding: 0;
    background: transparent;
    &:hover {
      border-bottom: 1px solid #cd0000;
    }
    &:focus,
    &:focus-within,
    &:active {
      color: #fff;
      border: none;
      box-shadow: none;
    }
    &.ant-radio-button-wrapper-checked {
      border-bottom: 1px solid #cd0000;
      border-radius: 0;
      &:hover {
        color: #cd0000;
      }
    }
  }
`
