export const parseUrl = (pattern: string, key: string, url?: string): string => {
  let path: string

  url ? (path = url) : (path = window.location.search)

  const pageUrl = new URLSearchParams(path.split(pattern)[1])
  const params = Object.fromEntries(pageUrl.entries())

  return params[key]
}
