import React, { useContext } from 'react'
import Search from 'antd/lib/input/Search'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import { RELOAD } from '../../reduser/Reduser'
import { log } from 'util'

const MySearch = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext<ContextType>(AppContext)
  const navigate = useNavigate()

  const onSearch = (e: any) => {
    if (!e) return

    dispatch({
      type: RELOAD,
      payload: !state.reload,
    })

    navigate(`/search?search=${e}`)
  }

  return <Search placeholder={t('h45')} allowClear onSearch={onSearch} style={{ width: 200 }} max="50" min="3" />
}

export default MySearch
