import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Button, Col, Dropdown, Form, Input, InputNumber, MenuProps, message, Modal, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { getRequestAuth, postRequestAuth } from '../../request/request'
import InputMask from 'react-input-mask'
import { JWT, ME, REFRESH, RELOAD } from '../../reduser/Reduser'
import { FormProvider } from 'antd/es/form/context'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import validatorServerErrors from '../ValidatorServerErrors/ValidatorServerErrors'
import { UserOutlined } from '@ant-design/icons'

type FieldType = {
  username?: string
  password?: string
  remember?: string
}

const MyAccount = () => {
  const { t } = useTranslation()
  const [isSignIn, setIsSignIn] = useState(false)
  const [isForgot, setIsForgot] = useState(false)
  const [isRegistration, setIsRegistration] = useState(false)
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [user, setUser] = useState<any>(null)
  const [validationPhone] = Form.useForm()
  const [sendSmsCode] = Form.useForm()
  const [newPassword] = Form.useForm()
  const [registration] = Form.useForm()
  const [login] = Form.useForm()
  const { state, dispatch } = useContext<ContextType>(AppContext)
  const [validateErrors, setValidateErrors] = useState<any>({})
  const navigate = useNavigate()
  const phoneMask = '+375(99)999-99-99'
  const { user: userProps, is_verified } = state?.me || {}

  window.addEventListener('storageChanged', () => {
    setReload((prev) => !prev)
  })

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      getRequestAuth('/profile/about_me/')
        .then((res: any) => {
          setUser(res?.data)
          dispatch({
            type: ME,
            payload: res.data,
          })
        })
        .catch((err) => {
          dispatch({
            type: JWT,
            payload: null,
          })
          navigate('/')
          // validatorServerErrors(err.data)
        })
    }
  }, [reload, state.reload])

  const refreshForm = () => {
    validationPhone.resetFields()
    sendSmsCode.resetFields()
    newPassword.resetFields()
    registration.resetFields()
    login.resetFields()
    setValidateErrors({})
  }

  const showModalSignIn = () => {
    setIsSignIn(true)
    setIsForgot(false)
    setIsRegistration(false)
    setStep(1)
    refreshForm()
  }

  const showModalIsForgot = () => {
    setIsForgot(true)
    setIsSignIn(false)
    refreshForm()
  }

  const showModalIsRegistration = () => {
    setIsRegistration(true)
    setIsForgot(false)
    setIsSignIn(false)
    refreshForm()
    setStep(1)
  }

  const handleCancel = () => {
    setIsSignIn(false)
    setIsForgot(false)
    setIsRegistration(false)
    setStep(1)
    setValidateErrors({})
    refreshForm()
  }

  const validatePhone = (rule: any, value: any) => {
    return new Promise((resolve: any, reject: any) => {
      const cleanedValue = value.replace(/[^\d+]/g, '')
      if (cleanedValue?.length === 13) {
        resolve()
      } else {
        reject(t('h70'))
      }
    })
  }

  const clearAuthorization = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('refresh')
  }

  const onFinish = (values: any, type?: string) => {
    const body = {
      ...values,
      phone: values?.phone?.replace(/[^\d+]/g, ''),
    }
    setIsLoading(true)

    if (type === 'login') {
      clearAuthorization()
      postRequestAuth('/auth/login/', body)
        .then((res: any) => {
          setReload((e) => !e)
          localStorage.setItem('jwt', res?.data?.access_token)
          localStorage.setItem('refresh', res?.data?.refresh_token)

          dispatch({
            type: JWT,
            payload: res?.data?.access_token,
          })

          dispatch({
            type: REFRESH,
            payload: res?.data?.refresh_token,
          })

          handleCancel()
          setIsLoading(false)
        })
        .catch((err) => {
          clearAuthorization()
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }
    if (type === 'new-password') {
      postRequestAuth('/auth/set_new_password/', body)
        .then((res) => {
          setIsLoading(false)
          message.success(res?.data?.status)
          handleCancel()
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }

    if (type === 'registration') {
      postRequestAuth('/auth/register/', body)
        .then((res) => {
          message.success(res?.data?.status)
          setIsLoading(false)
          setValidateErrors({})
          handleCancel()
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }

    //step 1 registration
    if (type === 'validation-phone') {
      postRequestAuth('/auth/send_sms_registration/', body)
        .then((res) => {
          registration.setFieldsValue({
            phone: body.phone,
          })
          setStep(2)
          setIsLoading(false)
          setValidateErrors({})
          message.success(res?.data?.status)
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }

    if (type === 'validation-code') {
      postRequestAuth('/auth/phone_validation/', body)
        .then((res) => {
          setIsLoading(false)
          message.success(res?.data?.status)
          // showModalSignIn()
          handleCancel()
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }

    if (type === 'send_sms_code') {
      postRequestAuth('/auth/send_sms_code/', body)
        .then((res) => {
          setIsLoading(false)
          setValidateErrors({})
          newPassword.setFieldsValue({
            phone: body.phone,
            code: res.data.code,
          })
          message.success(res?.data?.status)
          setStep(2)
        })
        .catch((err) => {
          setIsLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const handleExit = (e: any) => {
    e.preventDefault()
    localStorage.removeItem('jwt')
    localStorage.removeItem('refresh')
    dispatch({
      type: JWT,
      payload: null,
    })
    dispatch({
      type: ME,
      payload: null,
    })
    navigate('/')
  }

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: <NavLink to={`/cabinet/profile`}>{t('profile.p1')}</NavLink>,
    },
  ]

  if (is_verified) {
    items.push({
      key: 'my-trophies',
      label: (
        <NavLink to={`/cabinet/my-trophies`}>
          {userProps?.role === 'MODERATOR' || userProps?.role === 'ADMINISTRATOR' ? 'Трофеи' : t('profile.p2')}
        </NavLink>
      ),
    })
  }

  if (userProps?.role && (userProps?.role === 'MODERATOR' || userProps?.role === 'ADMINISTRATOR')) {
    items.push({
      key: 'adding-hunting-grounds',
      label: <NavLink to={`/cabinet/adding-hunting-grounds`}>{t('profile.p5')}</NavLink>,
    })
  }

  if (userProps?.role && (userProps?.role === 'EXPERT')) {
    items.push(
      {
        key: 'user-management',
        label: <NavLink to={`/cabinet/user-management`}>{t('profile.p6')}</NavLink>,
      },
      {
        key: 'application-management',
        label: <NavLink to={`/cabinet/application-management`}>{t('profile.p7')}</NavLink>,
      },
  )
  }

  
  if (userProps?.role && (userProps?.role === 'MODERATOR' || userProps?.role === 'ADMINISTRATOR')) {
    items.push({
      key: 'statistic',
      label: <NavLink to={`/cabinet/statistic`}>{t('profile.p9')}</NavLink>,
    })
  }

  items.push({
    key: '3',
    label: (
      <NavLink onClick={handleExit} to={''}>
        {t('h71')}
      </NavLink>
    ),
    danger: true,
  })

  const handleNewCode = (form: any) => {
    const phone = form?.getFieldInstance('phone')?.props?.value?.replace(/[^\d+]/g, '') || ''

    if (!phone && phone.length === 0) {
      message.error(`${t('h92')} - ${t('h56')}`)
      return false
    }

    postRequestAuth('/auth/send_sms_code/', { phone })
      .then((res) => {
        setIsLoading(false)
        setValidateErrors({})
        newPassword.setFieldsValue({
          phone,
        })
        setValidateErrors({})
        message.success(res?.data?.status)
        // postRequestAuth('/auth/get_code_from_redis/', { phone }).then((res) => {
        //   setIsLoading(false)
        // })
      })
      .catch((err) => {
        setIsLoading(false)
        validatorServerErrors(err.data, setValidateErrors)
      })
  }

  // console.log("localStorage.getItem('jwt')", localStorage.getItem('jwt'))
  // console.log('state', state.jwt)

  return (
    <FormProvider>
      {!localStorage.getItem('jwt') ? (
        <Button
          type="primary"
          onClick={showModalSignIn}
          style={{ verticalAlign: 'top', backgroundColor: '#50682f', borderColor: '#67853d', marginLeft: 10 }}
        >
          {t('h47')}
        </Button>
      ) : (
        <div className="user_menu">
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space style={{ color: '#99b165' }}>
                <Avatar size="small" icon={<UserOutlined />} /> {user?.user?.email}
              </Space>
            </a>
          </Dropdown>
        </div>
      )}

      {/*LOGIN*/}
      <Modal forceRender title={t('h90')} open={isSignIn} onCancel={handleCancel} footer={null}>
        <Form
          form={login}
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'login')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t('h56')}
            name="phone"
            rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
            validateStatus={validateErrors?.phone ? 'error' : ''}
            help={validateErrors?.phone}
          >
            <InputMask mask={phoneMask}>
              <Input />
            </InputMask>
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h54')}
            name="password"
            rules={[{ required: true, message: `${t('h48')} ${t('h50')}` }]}
            validateStatus={validateErrors?.password ? 'error' : ''}
            help={validateErrors?.password}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: '#50682f', borderColor: '#67853d' }}
              className="color-2"
              loading={isLoading}
            >
              {t('h47')}
            </Button>
          </Form.Item>

          <Row>
            <Col span={12}>
              <div style={{ textAlign: 'left' }}>
                <span onClick={showModalIsRegistration} className="cursor-pointer" style={{ color: '#4f682a' }}>
                  {t('h58')}
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ textAlign: 'right' }}>
                <span onClick={showModalIsForgot} className="cursor-pointer" style={{ color: '#4f682a' }}>
                  {t('h52')}
                </span>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/*end LOGIN*/}

      {/*Новый пароль*/}
      <Modal forceRender title={t('h88')} open={isForgot} onCancel={handleCancel} footer={null}>
        {step === 1 && (
          <Form
            form={sendSmsCode}
            name="send_sms_code"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={(e) => onFinish(e, 'send_sms_code')}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t('h56')}
              name="phone"
              rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
              validateStatus={validateErrors?.phone ? 'error' : ''}
              help={validateErrors?.phone}
            >
              <InputMask mask={phoneMask}>
                <Input />
              </InputMask>
            </Form.Item>

            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                loading={isLoading}
                htmlType="submit"
                style={{ backgroundColor: '#50682f', borderColor: '#67853d' }}
              >
                {t('h60')}
              </Button>
            </Form.Item>

            <Row>
              <Col span={12}>
                <div style={{ textAlign: 'left' }}>
                  <span className="cursor-pointer" onClick={() => showModalSignIn()} style={{ color: '#4f682a' }}>
                    {t('h47')}
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: 'right' }}>
                  <span className="cursor-pointer" onClick={showModalIsRegistration} style={{ color: '#4f682a' }}>
                    {t('h58')}
                  </span>
                </div>
              </Col>
            </Row>
          </Form>
        )}

        {step === 2 && (
          <Form
            form={newPassword}
            name="basic"
            layout="vertical"
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={(e) => onFinish(e, 'new-password')}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label={t('h49')}
              name="email"
              rules={[{ required: true, type: 'email', message: `${t('h48')} ${t('h49')}` }]}
              validateStatus={validateErrors?.email ? 'error' : ''}
              help={validateErrors?.email}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('h56')}
              name="phone"
              validateTrigger="onBlur"
              rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
              validateStatus={validateErrors?.phone ? 'error' : ''}
              help={validateErrors?.phone}
            >
              <InputMask mask={phoneMask}>
                <Input />
              </InputMask>
            </Form.Item>
            <Form.Item<FieldType>
              label={t('h54')}
              name="new_password"
              rules={[
                { required: true, message: `${t('h48')} ${t('h50')}` },
                { min: 8, message: 'Минимальное кол-во символов 8' },
              ]}
              validateStatus={validateErrors?.new_password ? 'error' : ''}
              help={validateErrors?.new_password}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t('h55')}
              dependencies={['new_password']}
              validateStatus={validateErrors?.confirm_password ? 'error' : ''}
              help={validateErrors?.confirm_password}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t('h68')} ${t('h50')}`,
                },
                { min: 8, message: 'Минимальное кол-во символов 8' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('h69')))
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item<FieldType>
              label={t('h57')}
              name="code"
              style={{ marginBottom: 5 }}
              rules={[
                { required: true, message: `${t('h48')} ${t('h57')}` },
                {
                  pattern: /^[0-9]+$/,
                  message: t('h93'),
                },
              ]}
              validateStatus={validateErrors?.code ? 'error' : ''}
              help={validateErrors?.code}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
            <span className="cursor-pointer" onClick={() => handleNewCode(newPassword)} style={{ color: '#4f682a' }}>
              {t('h72')}
            </span>
            <br />
            <br />
            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                loading={isLoading}
                htmlType="submit"
                style={{ backgroundColor: '#50682f', borderColor: '#67853d' }}
              >
                {t('h89')}
              </Button>
            </Form.Item>
            <Row>
              <Col span={12}>
                <div style={{ textAlign: 'left' }}>
                  <span className="cursor-pointer" onClick={showModalSignIn} style={{ color: '#4f682a' }}>
                    {t('h47')}
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: 'right' }}>
                  <span className="cursor-pointer" onClick={showModalIsRegistration} style={{ color: '#4f682a' }}>
                    {t('h58')}
                  </span>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
      {/*end Новый пароль*/}

      {/*РЕГИСТРАЦИЯ*/}
      <Modal
        forceRender
        title={step === 1 ? t('h58') : t('h74')}
        open={isRegistration}
        onCancel={handleCancel}
        footer={null}
      >
        {step === 1 && (
          <Form
            form={validationPhone}
            name="validation-phone"
            layout="vertical"
            onFinish={(e) => onFinish(e, 'validation-phone')}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t('h56')}
              name="phone"
              rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
              validateStatus={validateErrors?.phone ? 'error' : ''}
              help={validateErrors?.phone}
            >
              <InputMask mask={phoneMask}>
                <Input />
              </InputMask>
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ backgroundColor: '#50682f', borderColor: '#67853d' }}
              >
                {t('h60')}
              </Button>
            </Form.Item>
          </Form>
        )}
        {step === 2 && (
          <Form
            form={registration}
            name="registration"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={(e) => onFinish(e, 'registration')}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label={t('h49')}
              name="email"
              rules={[{ required: true, type: 'email', message: `${t('h48')} ${t('h49')}` }]}
              validateStatus={validateErrors?.email ? 'error' : ''}
              help={validateErrors?.email}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('h56')}
              name="phone"
              rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
              validateStatus={validateErrors?.phone ? 'error' : ''}
              help={validateErrors?.phone}
            >
              <InputMask mask={phoneMask}>
                <Input />
              </InputMask>
            </Form.Item>

            <Form.Item<FieldType>
              label={t('h54')}
              name="password"
              rules={[
                { required: true, message: `${t('h48')} ${t('h50')}` },
                { min: 8, message: `${t('h75')} 8` },
              ]}
              validateStatus={validateErrors?.password ? 'error' : ''}
              help={validateErrors?.password}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="re_password"
              label={t('h55')}
              dependencies={['password']}
              validateStatus={validateErrors?.re_password ? 'error' : ''}
              help={validateErrors?.re_password}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t('h48')} ${t('h54')}`,
                },
                { min: 8, message: `${t('h75')} 8` },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('h69')))
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<FieldType>
              label={t('h57')}
              name="code"
              style={{ marginBottom: 5 }}
              rules={[
                { required: true, message: `${t('h48')} ${t('h57')}` },
                {
                  pattern: /^[0-9]+$/,
                  message: t('h93'),
                },
              ]}
              validateStatus={validateErrors?.code ? 'error' : ''}
              help={validateErrors?.code}
            >
              <Input style={{ width: 200 }} />
            </Form.Item>

            <span className="cursor-pointer" onClick={() => handleNewCode(registration)} style={{ color: '#4f682a' }}>
              {t('h72')}
            </span>
            <br />
            <br />
            <p className="security-policy">{t('h172')}</p>
            <br />

            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ backgroundColor: '#50682f', borderColor: '#67853d' }}
              >
                {t('h59')}
              </Button>
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
              <Button type="link" onClick={showModalSignIn} style={{ color: '#4f682a' }}>
                {t('h47')}
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </FormProvider>
  )
}

export default MyAccount
