import React, { FormEvent, useContext, useEffect, useState } from 'react'
import './style/styleProfile.scss'
import { Alert, Button, DatePicker, Form, Input, Layout, Menu, message, Tabs, TabsProps } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { WrapperData } from '../../style/style'
import Sider from 'antd/es/layout/Sider'
import { adminMenu } from '../../lists/adminMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getRequestAuth, postRequestAuth, postRequestAuthNew, putRequestAuth } from '../../request/request'
import InputMask from 'react-input-mask'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import moment from 'moment'
import validatorServerErrors from '../../modules/ValidatorServerErrors/ValidatorServerErrors'
import { errors } from '../../function/errors'
import { JWT, ME } from '../../reduser/Reduser'
import BottomMenuInfo from '../../modules/BottomMenuInfo/BottomMenuInfo'

type FieldType = {
  username?: string
  password?: string
  remember?: string
}

const Profile = () => {
  const { state, dispatch } = useContext<ContextType>(AppContext)
  const [add] = Form.useForm()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [userId, setUserId] = useState(null)
  const type = pathname.split('/cabinet/')[1]
  const [about_me] = Form.useForm()
  const [form_phone] = Form.useForm()
  const [form_email] = Form.useForm()
  const [form_password] = Form.useForm()
  const [form_change_phone] = Form.useForm()
  const [step, setStep] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const [dateBirthday, setDateBirthday] = useState(null)
  const [phone, setPhone] = useState(null)
  const [userPhone, setUserPhone] = useState(null)
  const [changePhone, setChangePhone] = useState(true)
  const [code, setCode] = useState('')
  const [reload, setReload] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [validateErrors, setValidateErrors]: any = useState({})
  const dateFormat = 'DD-MM-YYYY'
  const dateFormatCalendar = ['DD-MM-YYYY', 'DD.MM.YYYY']
  const { user, is_email_verified } = state?.me || {}
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false)
  const [breakpoint, setBreakpoint] = useState(false)

  const handleCollapse = (collapsed: boolean, type: string) => {
    if (type === 'clickTrigger') setCollapsed(collapsed)
  }

  const handleClickMenu = () => {
    if (breakpoint) setCollapsed(true)
  }

  useEffect(() => {
    getRequestAuth('/profile/about_me/')
      .then((res: any) => {
        const {
          first_name,
          last_name,
          middle_name,
          date_birthday,
          user: { id, phone, email },
        } = res?.data || {}

        const newDate: any = moment(date_birthday, dateFormat)

        setUserId(id)
        setDateBirthday(date_birthday)

        about_me.setFieldsValue({
          first_name,
          last_name,
          middle_name,
          date_birthday: date_birthday ? newDate : '',
        })

        form_email.setFieldsValue({
          email,
        })

        setUserPhone(phone)
        form_phone.setFieldsValue({
          phone,
        })
      })
      .catch((err) => {
        validatorServerErrors(err.data)
        dispatch({
          type: JWT,
          payload: null,
        })
        navigate('/cabinet')
      })
  }, [reload, state.lang])

  const convertDate = (value: any) => {
    return moment(value).format(dateFormat)
  }

  const disabledDate = (current: any) => {
    const minDate = new Date(1900, 0, 1)
    const maxDate = moment()
    return current && (current < minDate || current > maxDate)
  }

  const clearForm = () => {
    setStep(1)
    about_me.resetFields()
    form_phone.resetFields()
    form_email.resetFields()
    form_password.resetFields()
    form_change_phone.resetFields()
  }

  const onFinish = (values: any, type: string) => {
    setLoading(true)

    if (type === 'about_me') {
      const date_birthday = convertDate(values.date_birthday)

      if (dateBirthday) {
        putRequestAuth('/profile/update_profile/', { ...values, user: userId, date_birthday })
          .then(() => {
            setStep(1)
            setLoading(false)
            message.success(t('h80'))
            setValidateErrors(null)
            setReload((e: boolean) => !e)
            dispatch({
              type: ME,
              payload: { ...state.me, is_verified: true },
            })
          })
          .catch((err) => {
            setLoading(false)
            validatorServerErrors(err.data, setValidateErrors)
          })
      } else {
        postRequestAuth('/profile/create_profile/', { ...values, user: userId, date_birthday })
          .then(() => {
            setStep(1)
            setLoading(false)
            message.success(t('h80'))
            setValidateErrors(null)
            setReload((e: boolean) => !e)
            dispatch({
              type: ME,
              payload: { ...state.me, is_verified: true },
            })
          })
          .catch((err) => {
            setLoading(false)
            validatorServerErrors(err.data, setValidateErrors)
          })
      }
    }
    if (type === 'form_email') {
      delete values.email

      postRequestAuth('/profile/change_email/', values)
        .then((res) => {
          message.success(res?.data?.status)
          setLoading(false)
          // removeCookiesWithRetries({
          //   cookieNames: ['jwt', 'refresh'],
          //   maxRetries: 10,
          //   delay: 100,
          //   cookies,
          //   removeCookie,
          //   navigate,
          //   dispatch,
          // })
          // navigate('/')
        })
        .catch((err) => {
          setLoading(false)
          validatorServerErrors(err.data, setValidateErrors)
        })
    }
    if (type === 'form_phone') {
      delete values.phone
      const new_phone = values?.new_phone?.replace(/[^\d+]/g, '')
      postRequestAuth('/profile/change_phone/', { ...values, new_phone })
        .then((res) => {
          setPhone(new_phone)
          message.success(res?.data?.status)
          setLoading(false)
          setChangePhone(false)
          // postRequestAuthNew('/auth/get_code_from_redis/', { phone: new_phone })
          //   .then((res) => {
          //     setCode(res?.data?.code)
          //     setChangePhone(false)
          //   })
          //   .catch((err) => {
          //     console.log('err', err)
          //   })
        })
        .catch((err) => {
          validatorServerErrors(err.data, setValidateErrors)
          setLoading(false)
        })
    }
    if (type === 'form_change_phone') {
      const phone = values?.new_phone?.replace(/[^\d+]/g, '')

      postRequestAuth('/profile/confirm_new_phone/', { ...values, phone })
        .then((res) => {
          message.success(res?.data?.status)
          setLoading(false)
          setChangePhone(false)

          // removeCookiesWithRetries({
          //   cookieNames: ['jwt', 'refresh'],
          //   maxRetries: 10,
          //   delay: 100,
          //   cookies,
          //   removeCookie,
          //   navigate,
          //   dispatch,
          // })

          localStorage.removeItem('jwt')
          localStorage.removeItem('refresh')
          dispatch({
            type: JWT,
            payload: null,
          })
          navigate('/')
        })
        .catch((err) => {
          validatorServerErrors(err.data, setValidateErrors)
          setLoading(false)
        })
    }
    if (type === 'form_password') {
      postRequestAuth('/profile/change_password/', values)
        .then((res) => {
          message.success(res?.data?.status)
          setLoading(false)
          // removeCookiesWithRetries({
          //   cookieNames: ['jwt', 'refresh'],
          //   maxRetries: 10,
          //   delay: 100,
          //   cookies,
          //   removeCookie,
          //   navigate,
          //   dispatch,
          // })
          // navigate('/')
        })
        .catch((err) => {
          validatorServerErrors(err.data, setValidateErrors)
          setLoading(false)
        })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('errorInfo', errorInfo)
  }

  const onChange = (key: string) => {
    setValidateErrors({})
    setReload((e: boolean) => !e)
    setStep(1)
    setChangePhone(true)
    form_phone.setFieldsValue({
      password: '',
    })
    form_email.setFieldsValue({
      password: '',
    })
  }

  const handleNewCode = () => {
    if (!userPhone) {
      message.error(`${t('h92')} - ${t('h56')}`)
      return false
    }

    postRequestAuth('/auth/send_sms_code/', { phone: userPhone })
      .then((res) => {
        message.success(res?.data?.status)
      })
      .catch((err) => {
        validatorServerErrors(err.data, setValidateErrors)
      })
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('h76'),
      children: (
        <Form
          form={about_me}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'about_me')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label={t('h61')}
            name="last_name"
            rules={[
              { required: true, message: `${t('h48')} ${t('h67')}` },
              { max: 30, message: `${t('h171')}: 30` },
            ]}
            validateStatus={validateErrors?.last_name ? 'error' : ''}
            help={validateErrors?.last_name}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h62')}
            name="first_name"
            rules={[{ max: 30, message: `${t('h171')}: 30` }]}
            validateStatus={validateErrors?.first_name ? 'error' : ''}
            help={validateErrors?.first_name}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h63')}
            name="middle_name"
            rules={[{ max: 30, message: `${t('h171')}: 30` }]}
            validateStatus={validateErrors?.middle_name ? 'error' : ''}
            help={validateErrors?.middle_name}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h65')}
            name="date_birthday"
            rules={[{ required: true, message: `${t('h48')} ${t('h65')}` }]}
            validateStatus={validateErrors?.date_birthday ? 'error' : ''}
            help={validateErrors?.date_birthday}
          >
            <DatePicker
              showToday={false}
              placeholder={t('h87')}
              format={dateFormatCalendar}
              style={{ width: 200 }}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <div className="text-center">
            <Button type="primary" htmlType="submit" className="color_btn">
              {dateBirthday ? t('h60') : t('h81')}
            </Button>
          </div>
        </Form>
      ),
    },
    {
      key: '2',
      label: t('h77'),
      children: changePhone ? (
        <Form
          form={form_phone}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'form_phone')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label={t('h64')}
            name="phone"
            rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
            validateStatus={validateErrors?.phone ? 'error' : ''}
            help={validateErrors?.phone}
          >
            <InputMask mask="+375(99)999-99-99" disabled>
              <Input />
            </InputMask>
          </Form.Item>

          {step === 2 && (
            <>
              <Form.Item<FieldType>
                label={t('h83')}
                name="new_phone"
                rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
                validateStatus={validateErrors?.new_phone ? 'error' : ''}
                help={validateErrors?.new_phone}
              >
                <InputMask mask="+375(99)999-99-99">
                  <Input />
                </InputMask>
              </Form.Item>
              <Form.Item<FieldType>
                label={t('h54')}
                name="password"
                rules={[{ required: true, message: `${t('h48')} ${t('h54')}` }]}
                validateStatus={validateErrors?.password ? 'error' : ''}
                help={validateErrors?.password}
              >
                <Input.Password />
              </Form.Item>

              <div className="text-center">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {t('h60')}
                </Button>
              </div>
            </>
          )}

          {step === 1 && (
            <div className="text-center">
              <Button type="primary" onClick={() => setStep(2)} className="color_btn">
                {t('h82')}
              </Button>
            </div>
          )}
        </Form>
      ) : (
        <Form
          form={form_change_phone}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'form_change_phone')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label={t('h64')}
            name="phone"
            rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
            validateStatus={validateErrors?.phone ? 'error' : ''}
            help={validateErrors?.phone}
          >
            <InputMask mask="+375(99)999-99-99" disabled>
              <Input />
            </InputMask>
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h83')}
            name="new_phone"
            initialValue={phone}
            rules={[{ required: true, message: `${t('h48')} ${t('h64')}` }]}
            validateStatus={validateErrors?.phone ? 'error' : ''}
            help={validateErrors?.phone}
          >
            <InputMask mask="+375(99)999-99-99">
              <Input />
            </InputMask>
          </Form.Item>
          <Form.Item<FieldType>
            label={t('h57')}
            name="code"
            initialValue={code}
            rules={[{ required: true, message: `${t('h48')} ${t('h57')}` }]}
            validateStatus={validateErrors?.code ? 'error' : ''}
            help={validateErrors?.code}
          >
            <Input />
          </Form.Item>
          <p>
            <span className="cursor-pointer" onClick={handleNewCode} style={{ color: '#4f682a' }}>
              {t('h72')}
            </span>
          </p>
          <div className="text-center">
            <Button type="primary" htmlType="submit" loading={isLoading} className="color_btn">
              {t('h60')}
            </Button>
          </div>
        </Form>
      ),
    },
    {
      key: '3',
      label: t('h78'),
      children: (
        <Form
          form={form_email}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'form_email')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label={t('h49')}
            name="email"
            rules={[{ required: true, type: 'email', message: `${t('h48')} ${t('h49')}` }]}
            validateStatus={validateErrors?.new_email ? 'error' : ''}
            help={validateErrors?.new_email}
          >
            <Input disabled />
          </Form.Item>
          {step === 2 && (
            <>
              <Form.Item<FieldType>
                label={t('h86')}
                name="new_email"
                rules={[{ required: true, type: 'email', message: `${t('h48')} ${t('h49')}` }]}
                validateStatus={validateErrors?.new_email ? 'error' : ''}
                help={validateErrors?.new_email}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label={t('h54')}
                name="password"
                rules={[{ required: true, message: `${t('h48')} ${t('h54')}` }]}
                validateStatus={validateErrors?.password ? 'error' : ''}
                help={validateErrors?.password}
              >
                <Input.Password />
              </Form.Item>

              <div className="text-center">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {t('h60')}
                </Button>
              </div>
            </>
          )}

          {step === 1 && (
            <div className="text-center">
              <Button type="primary" onClick={() => setStep(2)} className="color_btn">
                {t('h85')}
              </Button>
            </div>
          )}
        </Form>
      ),
    },
    {
      key: '4',
      label: t('h79'),
      children: (
        <Form
          form={form_password}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={(e) => onFinish(e, 'form_password')}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label={t('h66')}
            name="password"
            rules={[{ required: true, message: `${t('h48')} ${t('h66')}` }]}
            validateStatus={validateErrors?.password ? 'error' : ''}
            help={validateErrors?.password}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item<FieldType>
            label={t('h51')}
            name="new_password"
            rules={[{ required: true, message: `${t('h48')} ${t('h51')}` }]}
            validateStatus={validateErrors?.new_password ? 'error' : ''}
            help={validateErrors?.new_password}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label={t('h55')}
            dependencies={['new_password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: `${t('h68')} ${t('h51')}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('h69')))
                },
              }),
            ]}
            validateStatus={validateErrors?.confirm_password ? 'error' : ''}
            help={validateErrors?.confirm_password}
          >
            <Input.Password />
          </Form.Item>

          <div className="text-center">
            <Button type="primary" htmlType="submit" className="color_btn">
              {t('h60')}
            </Button>
          </div>
        </Form>
      ),
    },
  ]

  const handleRequestLetter = () => {
    setLoadingEmail(true)

    const body = {
      email: user.email,
    }

    postRequestAuth('/profile/verify_email/', body)
      .then((res) => {
        message.success(res.data.status)
        setLoadingEmail(false)
      })
      .catch((err) => {
        setLoadingEmail(false)
        errors(err)
      })
  }

  const handleFinish = (values: any) => {
    handleRequestLetter()
  }

  return (
    <Layout className="br-12">
      <Sider
        width={270}
        breakpoint="lg"
        collapsedWidth="0"
        className="site-layout-background"
        collapsed={collapsed}
        onBreakpoint={(broken) => setBreakpoint(broken)}
        onCollapse={(collapsed: boolean, type: string) => handleCollapse(collapsed, type)}
        // style={{ paddingBottom: 600 }}
      >
        <Menu className="w-240" selectedKeys={[type]} mode="inline" items={adminMenu()} onClick={handleClickMenu} />
        <BottomMenuInfo type="man" />
      </Sider>
      <Content>
        <WrapperData>
          {is_email_verified ? (
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          ) : (
            <>
              <Alert message={t('h123')} description={t('h124')} type="warning" showIcon />
              {user?.email && user?.email?.trim() !== '' ? (
                <Button
                  type="primary"
                  className="color_btn"
                  loading={loadingEmail}
                  onClick={handleRequestLetter}
                  style={{
                    marginTop: 20,
                  }}
                >
                  {t('h125')}
                </Button>
              ) : (
                <Form
                  name=""
                  layout="vertical"
                  form={add}
                  autoComplete="off"
                  onFinish={handleFinish}
                  style={{ marginTop: 20 }}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: 'email', message: `${t('h48')} ${t('h49')}` }]}
                  >
                    <Input
                      style={{
                        width: '50%',
                      }}
                    />
                  </Form.Item>

                  <Button htmlType="submit" type="primary" className="color_btn" loading={loadingEmail}>
                    {t('h125')}
                  </Button>
                </Form>
              )}
            </>
          )}
        </WrapperData>
      </Content>
    </Layout>
  )
}

export default Profile
