import React from 'react'
import { NavLink } from 'react-router-dom'

export const listMenu = (t: any) => [
  {
    key: 1,
    label: <NavLink to="/">{t('m1')}</NavLink>,
  },
  {
    key: 2,
    label: <NavLink to="/boor?type=1">{t('m2')}</NavLink>,
  },
  {
    key: 3,
    label: <NavLink to="/membership?type=5">{t('m4')}</NavLink>,
  },
  {
    key: 4,
    label: <NavLink to="/legal-basis?type=12">{t('m5')}</NavLink>,
  },
  {
    key: 5,
    label: <NavLink to="/archive-news">{t('m10')}</NavLink>,
  },
  {
    key: 6,
    label: <NavLink to="/our-everything?type=13">{t('m11')}</NavLink>,
  },
  {
    key: 7,
    label: <NavLink to="/contact?type=3">{t('m12')}</NavLink>,
  },
]
