import HomeAdmin from '../room/Admin/pages/HomeAdmin/HomeAdmin'
import Statistics from '../room/Admin/pages/Statistics/Statistics'
import React from 'react'

export const menuAdmin = [
  {
    id: 1,
    path: '/',
    element: <HomeAdmin />,
  },
  {
    id: 2,
    path: '/statistics',
    element: <Statistics />,
  },
]
