import React, { useContext, useRef } from 'react'
import { AppContext } from '../../../App'
import { ContextType } from '../../../type/type'
import { ROLE } from '../../../reduser/Reduser'
import { Button, Input } from 'antd'
import { useCookies } from 'react-cookie'

const SignIn = () => {
  const { dispatch } = useContext<ContextType>(AppContext)
  const [, setCookie] = useCookies(['jwt', 'refresh', 'role'])
  // const [, , removeCookie] = useCookies(['jwt', 'refresh', 'role'])
  const tt: any = useRef(null)

  const test = () => {
    window.location.pathname = '/'
    // TODO
    // removeCookie('jwt')
    localStorage.removeItem('jwt')
    setCookie('role', tt.current.input.value)

    dispatch({
      type: ROLE,
      payload: tt.current.input.value,
    })
  }

  return (
    <p>
      <Input ref={tt} />
      <Button onClick={test}>SignIn</Button>
    </p>
  )
}

export default SignIn
