import Axios from 'axios'
import { config } from '../config'
import { Cookies } from 'react-cookie'
let headers = {}
let isRefreshing = false

export const refreshToken = async (err: any) => {
  const refresh = localStorage.getItem('refresh')
  if (+err.status === 401 || +err.status === 403) {
    localStorage.removeItem('jwt')

    if (!isRefreshing) {
      isRefreshing = true

      try {
        const response = await Axios.post(`${config.auth}/auth/jwt/refresh/`, { refresh })
        const { access } = response.data
        localStorage.setItem('jwt', access)

        const event = new Event('storageChanged')
        window.dispatchEvent(event)
        // window.location.reload()
        // localStorage.removeItem('refresh')
      } catch (error) {
        isRefreshing = false
        localStorage.removeItem('jwt')
        //localStorage.removeItem('refresh')
        return Promise.reject(err)
      } finally {
        isRefreshing = false
      }
    }
  }

  throw Object.prototype.hasOwnProperty.call(err, 'response') ? err?.response : err
}

export const postRequest = async (url: string, body: any) => {
  const params = { ...body }
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.post(config.endpoint + url, params, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const postRequestForm = async (url: string, body: any) => {
  const params = body
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.post(config.endpoint + url, params, {
    headers: { 'content-type': 'multipart/form-data', ...headers },
  })
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const postRequestAuth = async (url: string, body: any) => {
  const params = { ...body }
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.post(config.auth + url, params, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      const validErr = err.response || err
      return refreshToken(validErr)

      // const validErr = err.response || err
      // refreshToken(validErr)
    })
}

export const postRequestAuthNew = async (url: string, body: any) => {
  const params = { ...body }
  const cookies = new Cookies()

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  return Axios.post(config.auth + url, params, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      const validErr = err.response || err
      return refreshToken(validErr)

      // const validErr = err.response || err
      // refreshToken(validErr)
    })
}

export const getRequestNew = async (url: string) => {
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.get(config.endpoint + url, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const getRequest = async (url: string) => {
  const cookies = new Cookies()

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  return Axios.get(config.endpoint + url, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const getRequestBlob = async (url: string) => {
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
    'Content-Type': 'blob',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.get(config.endpoint + url, { headers, responseType: 'blob' })
    .then((res) => {
      return res
    })
    .catch((err) => {
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const getRequestAuth = async (url: string) => {
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.get(config.auth + url, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const putRequestAuth = async (url: string, body: any) => {
  const params = { ...body }
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.put(config.auth + url, params, { headers })
    .then((res) => {
      return res
    })
    .catch((err) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const patchRequestAuth = async (url: string, body?: any) => {
  const params = { ...body }
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }

  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.patch(config.auth + url, params, {
    headers: { ...headers },
  })
    .then((res) => {
      return res
    })
    .catch((err) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const patchRequestForm = async (url: string, body: any) => {
  const params = body
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }
  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.patch(config.endpoint + url, params, {
    headers: { 'content-type': 'multipart/form-data', ...headers },
  })
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const putRequestForm = async (url: string, body: any) => {
  const params = body
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }
  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.put(config.endpoint + url, params, {
    headers: { 'content-type': 'multipart/form-data', ...headers },
  })
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}

export const deleteRequestForm = async (url: string) => {
  const cookies = new Cookies()
  const jwt = localStorage.getItem('jwt')

  headers = {
    'Accept-Language': cookies.get('lang') || 'ru',
  }
  if (jwt) {
    headers = {
      ...headers,
      Authorization: `JWT ${jwt}`,
    }
  }

  return Axios.delete(config.endpoint + url, {
    headers: { 'content-type': 'multipart/form-data', ...headers },
  })
    .then((res: any) => {
      return res
    })
    .catch((err: any) => {
      // errorFn(err)
      const validErr = err.response || err
      return refreshToken(validErr)
    })
}
