import React from 'react'
import { WrapperUl } from './style/SocialNetworkStyle'
import { listIcon } from '../listSocialNetwork'
import { Tooltip } from 'antd'

const ItemSocialNetwork = ({ data }: any) => {
  return (
    data && (
      <WrapperUl>
        {data?.map(({ id, name, ref }: any) =>
          listIcon[name] ? (
            <li key={id}>
              <Tooltip title={name}>
                <a href={ref} target="_blank" rel="noopener noreferrer">
                  {listIcon[name]}
                </a>
              </Tooltip>
            </li>
          ) : (
            ''
          ),
        )}
      </WrapperUl>
    )
  )
}

export default ItemSocialNetwork
