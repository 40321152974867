import React from 'react'
import { Box } from '../../style/style'
import { Spin } from 'antd'

const MyLoading = () => {
  return (
    <Box className="loading text-center">
      <span style={{ marginBottom: 70, display: 'block' }}>Загрузка</span>
      <Spin />
    </Box>
  )
}

export default MyLoading
