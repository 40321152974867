import React, { useEffect, useState } from 'react'
import { WrapperIncrease } from './style/IncreaseStyle'
import { Tooltip } from 'antd'

const Increase = () => {
  const [activeId, setActiveId] = useState(localStorage.getItem('increase') || 1)

  const list = [
    {
      id: 1,
      classEl: 'n1',
      size: 16,
      tooltip: 'Масштаб 100%',
    },
    {
      id: 2,
      classEl: 'n2',
      size: 20,
      tooltip: 'Масштаб 125%',
    },
    {
      id: 3,
      classEl: 'n3',
      size: 24,
      tooltip: 'Масштаб 150%',
    },
  ]

  useEffect(() => {
    const local = localStorage.getItem('increase') || 1

    list.forEach(({ id, classEl }) => {
      if (+id === +local) {
        document.body.id = classEl
      }
    })
  }, [])

  const handleClick = (id: number, classEl: string) => {
    setActiveId(id)
    localStorage.setItem('increase', String(id))
    document.body.id = classEl
  }

  return (
    <WrapperIncrease>
      {list?.map(({ id, classEl, tooltip }: { id: number; classEl: string; tooltip: string }) => (
        <Tooltip title={tooltip} key={id}>
          <span
            className={`${classEl} ${+activeId === id ? 'active' : ''}`}
            onClick={() => handleClick(id, classEl)}
          >
            А+
          </span>
        </Tooltip>
      ))}
    </WrapperIncrease>
  )
}

export default Increase
