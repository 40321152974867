import React from 'react'
import Icon, { YoutubeOutlined } from '@ant-design/icons'
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons'
import { ReactComponent as TelegramIcon } from './../../static/telegramIcon.svg'

export const listIcon: any = {
  facebook: <FacebookOutlined />,
  instagram: <InstagramOutlined />,
  youtube: <YoutubeOutlined />,
  telegram: <Icon component={TelegramIcon} />,
}
