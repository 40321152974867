import React, { useContext, useEffect, useState } from 'react'
import { Title, WrapperData } from '../../style/style'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { parseUrl } from '../../function/parseUrl'
import { getRequest } from '../../request/request'
import { errors } from '../../function/errors'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import { Pagination } from 'antd'
import './style/styleSearch.scss'

const PageSearch = () => {
  const { t } = useTranslation()
  const { state } = useContext<ContextType>(AppContext)
  const [textSearch, setTextSearch] = useState('')
  const [data, setData] = useState<any>(null)
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)
  const [searchValue, setSearchValue] = useState<null | string>(null)
  const [errorSearch, setErrorSearch] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    setData(null)
    setErrorSearch(null)

    setTextSearch('')
    const searchData = parseUrl('?', 'search')
    let url = `/search/?search=${searchData}`

    const page = parseUrl('&', 'page')

    if (page) {
      setCurrent(+page)
      url += `&page=${page}`
    }

    setSearchValue(searchData)

    getRequest(url)
      .then((res) => {
        setData(res?.data?.results)
        setTotal(res?.data?.count)
      })
      .catch((err) => {
        Object.prototype.hasOwnProperty.call(err.data, 'detail') ? setErrorSearch(err.data.detail) : errors(err)
      })

    setTextSearch(searchData)
  }, [state.reload, current])

  const handlePopup = (page: number) => {
    navigate(`/search?search=${searchValue}&page=${page}`)
    setCurrent(page)
  }

  return (
    <WrapperData className="br-12">
      <Title>
        {t('h45')}: {textSearch}
      </Title>
      {errorSearch && <p>{errorSearch}</p>}

      {data && (
        <ul className="search-list">
          {data.map(
            ({
              id,
              title,
              text,
              page,
            }: {
              id: number | string
              title: string
              text: string
              page: number | string
            }) => (
              <li key={id + title}>
                <h3>{title}</h3>
                <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
                <Link to={`/search/${id}?request=${page}`}>{t('h46')}</Link>
              </li>
            ),
          )}
        </ul>
      )}
      <br />
      <br />
      <div className="text-center">
        {total >= 5 && <Pagination pageSize={10} current={current} total={total} onChange={handlePopup} />}
      </div>
    </WrapperData>
  )
}

export default PageSearch
