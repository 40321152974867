import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const RestrictedRoutes = () => {
  const jwt = localStorage.getItem('jwt')

  const hasAccess = !!jwt

  return hasAccess ? <Outlet /> : <Navigate to="/" />
}

export default RestrictedRoutes
