import React from 'react'
import { useTranslation } from 'react-i18next'
import { BottomImg, BottomInfo } from './style/BottomMenuInfoStyle'
import MenuLogo from '../../static/media/logoMenu2.png'
import MenuLogoWoman from '../../static/media/logoMenu3.png'

const BottomMenuInfo = ({ type }: { type: 'man' | 'woman' }) => {
  const { t, i18n } = useTranslation()
  const date = '11.01.2022'
  const dateEn = '2022.11.01'
  const number = '№72954'
  const currentLanguage = i18n.language

  const listImg = {
    man: MenuLogo,
    woman: MenuLogoWoman,
  }

  return (
    <BottomInfo className="bottom_info_menu">
      <BottomImg>
        <img alt="example" src={type && listImg[type]} />
      </BottomImg>
      {type !== 'woman' && (
        <p>
          {t('h152')} {currentLanguage === 'en' ? dateEn : date} <br />
          {number}
        </p>
      )}
    </BottomInfo>
  )
}
export default BottomMenuInfo
