const url = 'http://0.0.0.0:8000/api/v1'
const url2 = 'http://0.0.0.0:8000'

const url3 = 'https://bor.intechs.by/api/v1'
const url4 = 'https://bor.intechs.by'

const url5 = 'https://rgooboor.by/api/v1'
const url6 = 'https://rgooboor.by'

export const config = {
  defaultImg: '',
  endpoint: url5,
  auth: url6,
}
