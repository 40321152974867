import styled from 'styled-components'

export const UpPage = styled.div`
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 10px;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  background-color: #505a3c;
  border-radius: 50%;
  &:hover {
    opacity: 0.7 !important;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
  }
`
