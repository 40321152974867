import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/en.json'
import translationRU from './locales/ru/ru.json'
import translationDE from './locales/de/de.json'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

//const fallbackLng = [cookies.get('lang')] && ['ru']
const fallbackLng = cookies.get('lang') ? [cookies.get('lang')] : ['ru']
const availableLanguages = ['ru', 'en', 'de']

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  de: {
    translation: translationDE,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,

  detection: {
    checkWhitelist: true,
  },

  debug: false,

  whitelist: availableLanguages,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
