import React, { useContext, useEffect, useState } from 'react'
import { BottomFooter, Footer } from '../../style/style'
import { Col, Row } from 'antd'
import { ReactComponent as LogoIcon } from '../../static/logo.svg'
import SocialNetwork from '../SocialNetwork/SocialNetwork'
import { getRequest } from '../../request/request'
import { errors } from '../../function/errors'
import { ContextType } from '../../type/type'
import { AppContext } from '../../App'
import { useTranslation } from 'react-i18next'

const FooterBox = () => {
  const { t } = useTranslation()
  const { state } = useContext<ContextType>(AppContext)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    getRequest('/info/info/')
      .then((res) => {
        setData(res?.data?.results)
      })
      .catch((err) => errors(err))
  }, [state.lang])

  return (
    <Footer>
      <BottomFooter>
        <div className="container">
          <Row gutter={15}>
            <Col span={24} md={3}>
              <LogoIcon style={{ height: 85 }} />
            </Col>
            {data && (
              <React.Fragment>
                <Col span={24} md={21} lg={7} xl={6}>
                  <p>
                    <strong>{t('h139')}.</strong>
                    <br />
                    <br />
                    {data[0]?.address[0] ? data[0]?.address[0].name : ''}
                  </p>
                  <br /> <br />
                </Col>
                <Col span={24} sm={{ span: 21, offset: 3 }} lg={{ span: 7, offset: 0 }} xl={6}>
                  <p>
                    <strong>{t('h138')}</strong>
                  </p>
                  <p> {data[0]?.address[0] ? data[0]?.address[0]?.name : ''}</p>
                  <br />
                  <p>
                    <b>{t('h137')}:</b>
                  </p>
                  {data[0]?.work_time
                    ? data[0]?.work_time?.map(({ id, name }: { id: number | string; name: string }) => (
                        <p className="mb-0" key={id}>
                          {name}
                        </p>
                      ))
                    : ''}
                </Col>
                <Col span={24} sm={{ span: 21, offset: 3 }} lg={{ span: 7, offset: 0 }} xl={5}>
                  <p>
                    <b>{t('h134')}:</b>
                  </p>
                  {t('h135')}: {data[0]?.phones} <br />
                  {t('h135')}/{t('Факс')}: {data[0]?.fax_phone}
                  <br />
                  E-mail: {data[0]?.email} <br /> <br />
                </Col>
                <Col span={24} sm={{ span: 21, offset: 3 }} lg={{ span: 7, offset: 0 }} xl={4}>
                  <>{data[0]?.link ? <SocialNetwork data={data[0]?.link} /> : ''}</>
                </Col>
              </React.Fragment>
            )}
          </Row>
        </div>
      </BottomFooter>
    </Footer>
  )
}

export default FooterBox
