import React from 'react'
import { Header, HeaderMenu, TopHeader } from '../../style/style'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import { listHeaderMenu } from '../TopMenu/list/listHeaderMenu'
import { useTranslation } from 'react-i18next'
import HeaderMenuBox from '../TopMenu/HeaderMenuBox'
import Language from '../Language/Language'
import TopMenu from '../Menu/TopMenu'
import MySearch from '../MySearch/MySearch'
import Increase from '../Increase/Increase'
import { ReactComponent as LogoIcon } from '../../static/logo.svg'
import MyAccount from '../MyAccount/MyAccount'

const HeaderBox = () => {
  const { t } = useTranslation()

  return (
    <Header className="header">
      <div className="container">
        <div className="text-right" style={{ marginTop: '-20px' }}>
          <Increase />
          <MySearch />
          <MyAccount />
        </div>

        <TopHeader>
          <Row gutter={25} align="middle">
            <Col xl={4} md={4} sm={12} xs={12}>
              <Link to="/">
                <LogoIcon className="h-logo" />
              </Link>
            </Col>
            <Col xl={{ span: 2, push: 18 }} md={{ span: 3, push: 17 }} sm={12} xs={12} className="text-right">
              <Language />
            </Col>

            <Col md={{ span: 17, pull: 3 }} xs={24} className="header-title-none">
              <h3 className="text-center" style={{ color: '#fff', marginBottom: 30 }}>
                {t('h139')}
              </h3>
            </Col>

            <Col xl={{ span: 18, pull: 2 }} md={24} xs={24} sm={24} className="line">
              <h3 className="text-center header-title">{t('h139')}</h3>
              <HeaderMenuBox data={listHeaderMenu(t)} />
            </Col>
          </Row>
        </TopHeader>
      </div>

      <HeaderMenu>
        <div className="container">
          <TopMenu />
        </div>
      </HeaderMenu>
    </Header>
  )
}

export default HeaderBox
