import React, { useEffect, useState } from 'react'
import { UpPage } from './style/PageUpStyle'
import { goUp } from '../../function/goUp'
import { ReactComponent as UpIcon } from './img/up.svg'

const PageUp = () => {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', () =>
      +document.documentElement.scrollTop >= 500 ? setVisible(true) : setVisible(false),
    )
  }, [])

  return visible ? (
    <UpPage className="fadeIn" onClick={() => goUp()}>
      <UpIcon />
    </UpPage>
  ) : null
}
export default PageUp
