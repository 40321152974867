import React, { useEffect, useState } from 'react'
import { getRequest } from '../../request/request'
import { errors } from '../../function/errors'
import { Carousel } from 'antd'

const BgAnimation = () => {
  const [data, setData] = useState<[] | null>(null)
  const settings = {
    autoplaySpeed: 15000,
    speed: 2500,
  }

  useEffect(() => {
    getRequest('/slider/')
      .then((res) => {
        setData(res?.data?.results)
      })
      .catch((err) => errors(err))
  }, [])

  return (
    <div className="wrapper-animation">
      {data && (
        <Carousel effect="fade" autoplay={true} dots={false} {...settings}>
          {data.map(({ id, file_path }: { id: number; file_path: string }) => (
            <div key={id}>
              <span style={{ backgroundImage: `url(${file_path})` }} />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  )
}

export default BgAnimation
