import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import NotFound from '../../pages/NotFound/NotFound'
import { renderRouter } from '../../function/renderRouter'
import { menuAdmin } from '../../routes/menuAdmin'

const IndexAdmin = () => {
  return (
    <>
      <ul>
        <Link to="/">Главная</Link>
        <Link to="/statistics">Статистика</Link>
      </ul>
      <Routes>
        {renderRouter(menuAdmin)}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default IndexAdmin
