import React, { useState } from 'react'
import './style/MenuStyle.scss'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { listMenu } from './list/listMenu'

const TopMenu = () => {
  const { t } = useTranslation()
  const [current, setCurrent] = useState('mail')

  const onClick: any['onClick'] = (e: any) => {
    setCurrent(e.key)
  }

  return (
    <>
      <div className="header-menu">
        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={listMenu(t)} />
      </div>
    </>
  )
}

export default TopMenu
