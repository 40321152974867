import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
  //padding: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  h3 {
    font-size: 50px;
  }
  img {
    max-width: 50%;
  }
`
