import React from 'react'
import nod from '../../static/nod.png'
import { NotFoundWrapper } from './style/NotFoundStyle'
import { useTranslation } from 'react-i18next'
import { WrapperData } from '../../style/style'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <NotFoundWrapper>
      <WrapperData>
        <h3>{t('e1')}</h3>
        <p>
          <img src={nod} alt="not found" />
        </p>
      </WrapperData>
    </NotFoundWrapper>
  )
}

export default NotFound
