import React from 'react'
import { message } from 'antd'
import { log } from 'util'

interface Error {
  attr: string | null
  code: string
  detail: string
}

interface validatorServerErrorsProps {
  callbackValidateForm?: (obj: { [key: string]: string }) => void
}

const validatorServerErrors = (err: Error, callbackValidateForm?: any, translate?: any) => {
  const { type, errors }: any = err || {}
  let obj: { [key: string]: string } | null = null
  const arrAttr: string[] = []
  const arrError: string[] = []

  const validateAttr = (el: any) => {
    el?.errors?.forEach(({ attr, detail }: { attr: string; detail: string }) => {
      if (attr) {
        if (attr === 'non_field_errors') {
          message.error(detail)
        } else {
          arrAttr.push(`${attr ? `${attr}: ` : ' '}${detail}`)
          obj = { ...obj, [attr]: detail }
        }
      } else {
        arrError.push(`${attr ? `${attr}: ` : ' '}${detail}`)
      }
    })

    const hasAttribute = el?.errors?.some((error: any) => {
      return error.attr !== null && error.attr !== 'null' && error.attr !== undefined
    })

    if (!hasAttribute) {
      message.error(arrError?.join(', '))
    }
  }

  if (type === 'validation_error') {
    validateAttr(err)
    callbackValidateForm && callbackValidateForm(obj)

    return null
  } else {
    if (errors) {
      const arrErrors = errors?.map(({ detail }: { detail: string }) => detail)
      message.error(arrErrors?.join(', '))
    } else {
      message.error(translate && translate('errors.e1'))
    }
  }

  return null
}

export default validatorServerErrors
