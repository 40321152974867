import styled from 'styled-components'

export const WrapperIncrease = styled.div`
  display: inline-block;
  margin: 0 10px 0 0;
  color: #fff;
  span {
    margin: 0 0 0 5px;
    cursor: pointer;
    &:first-child {
      font-size: 16px;
    }
    &:nth-child(2) {
      font-size: 20px;
    }
    &:last-child {
      font-size: 24px;
    }
    &:hover,
    &.active {
      color: #cd0000;
    }
  }
`
