import React from 'react'
import { Route } from 'react-router-dom'

interface menuType {
  id: number
  path: string
  element: any
}

export const renderRouter = (data: any) =>
  data.map(({ id, path, element }: menuType) => <Route key={id} path={path} element={element} />)
