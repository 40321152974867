import { Cookies } from 'react-cookie'
const cookies = new Cookies()

export const JWT = 0
export const SPINNING = 1
export const ME = 2
export const ROLE = 3
export const LANG = 4
export const RELOAD = 5
export const INCREASE = 6
export const REFRESH = 7
export const SORT = 8

export const initialState = {
  jwt: cookies.get('jwt') || null,
  refresh: cookies.get('refresh') || null,
  spinning: false,
  me: null,
  lang: cookies.get('lang') || 'ru',
  role: cookies.get('role') || '',
  reload: false,
  sort: null,
  increase: localStorage.getItem('increase') || 1,
}

export const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case JWT:
      return { ...state, jwt: action.payload }
    case REFRESH:
      return { ...state, refresh: action.payload }
    case SPINNING:
      return { ...state, spinning: action.payload }
    case ME:
      return { ...state, me: action.payload }
    case ROLE:
      return { ...state, role: action.payload }
    case LANG:
      return { ...state, lang: action.payload }
    case RELOAD:
      return { ...state, reload: action.payload }
    case INCREASE:
      return { ...state, increase: action.payload }
    case SORT:
      return { ...state, increase: action.sort }
  }
}
