import { message } from 'antd'

export const errors = (obj: any) => {
  if (!obj) return

  if (Object.prototype.hasOwnProperty.call(obj, 'data')) {
    if (!Array.isArray(obj.data)) return message.error('ERROR SERVER')

    const key = Object.keys(obj.data)
    const arrMessage = key.map((e) => obj.data[e])
    message.error(arrMessage?.join('; '))
  } else {
    message.error(obj.message)
  }
}
