import React, { useContext, useEffect, useState } from 'react'
import { Empty, Spin } from 'antd'
import { AppContext } from '../../App'
import { Title, WrapperData } from '../../style/style'
import { Text } from '../WrapperTextPage/style/WrapperTextPageStyle'
import { getRequest } from '../../request/request'
import { useParams } from 'react-router-dom'
import { errors } from '../../function/errors'
import { ContextType } from '../../type/type'

const AdvertisingItem = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any | null>(null)
  const { state } = useContext<ContextType>(AppContext)
  const { id } = useParams()

  useEffect(() => {
    setLoading(true)
    getRequest(`/sales/detail/${id}/`)
      .then((res) => {
        setData(res?.data)
        setLoading(false)
      })
      .catch((err) => {
        errors(err)
        setLoading(false)
      })
  }, [state.lang])

  return (
    <WrapperData className="br-12">
      {loading ? (
        <Spin spinning={true} />
      ) : data && Object.prototype.hasOwnProperty.call(data, 'id') ? (
        <>
          <Title>{data?.title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: data?.text }} />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </WrapperData>
  )
}

export default AdvertisingItem
