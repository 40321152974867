import { NavLink } from 'react-router-dom'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextType } from '../type/type'
import { AppContext } from '../App'

export const adminMenu = () => {
  const { t } = useTranslation()
  const { state } = useContext<ContextType>(AppContext)
  const { user, is_verified } = state?.me || {}

  const menuList = [
    {
      key: 'profile',
      label: <NavLink to={`/cabinet/profile`}>{t('profile.p1')}</NavLink>,
    },
  ]

  if (is_verified) {
    menuList.push({
      key: 'my-trophies',
      label: (
        <NavLink to={`/cabinet/my-trophies`}>
          {user.role === 'MODERATOR' || user.role === 'ADMINISTRATOR' ? t('profile.p4') : t('profile.p2')}
        </NavLink>
      ),
    })
  }

  if (user?.role && (user?.role === 'MODERATOR' || user?.role === 'ADMINISTRATOR')) {
    menuList.push({
      key: 'adding-hunting-grounds',
      label: <NavLink to={`/cabinet/adding-hunting-grounds`}>{t('profile.p5')}</NavLink>,
    })
  }

  if (user?.role && (user?.role === 'EXPERT')) {
    menuList.push(
      {
        key: 'user-management',
        label: <NavLink to={`/cabinet/user-management`}>{t('profile.p6')}</NavLink>,
      },
      {
        key: 'application-management',
        label: <NavLink to={`/cabinet/application-management`}>{t('profile.p7')}</NavLink>,
      },
    )
  }

  if (user?.role && (user?.role === 'MODERATOR' || user?.role === 'ADMINISTRATOR')) {
    menuList.push({
      key: 'statistic',
      label: <NavLink to={`/cabinet/statistic`}>{t('profile.p9')}</NavLink>,
    })
  }

  return menuList
}
